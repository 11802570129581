@font-face{font-family:spacesword;src:url(//static.hsappstatic.net/icons/static-2.583/fonts/spacesword-low.woff2) format("woff2"),url(//static.hsappstatic.net/icons/static-2.583/fonts/spacesword-low.woff) format("woff")}@font-face{font-family:spacesword;src:url(//static.hsappstatic.net/icons/static-2.583/fonts/spacesword-high.woff2) format("woff2"),url(//static.hsappstatic.net/icons/static-2.583/fonts/spacesword-high.woff) format("woff");font-weight:700}

[class*=private-icon__]{-webkit-font-smoothing:antialiased;-moz-osx-font-smoothing:grayscale;font-family:spacesword!important;font-feature-settings:"liga";font-variant:normal;font-weight:400;letter-spacing:0;text-transform:none}[class*=private-icon__][class*=high]{font-weight:700}





@font-face{font-display:swap;font-family:Lexend Deca;src:url(https://static.hsappstatic.net/ui-fonts/static-1.286/fonts/LexendDeca-Light.woff2) format("woff2"),url(https://static.hsappstatic.net/ui-fonts/static-1.286/fonts/LexendDeca-Light.woff) format("woff")}@font-face{font-display:swap;font-family:Lexend Deca;src:url(https://static.hsappstatic.net/ui-fonts/static-1.286/fonts/LexendDeca-Medium.woff2) format("woff2"),url(https://static.hsappstatic.net/ui-fonts/static-1.286/fonts/LexendDeca-Medium.woff) format("woff");font-weight:500}@font-face{font-display:swap;font-family:Lexend Deca;src:url(https://static.hsappstatic.net/ui-fonts/static-1.286/fonts/LexendDeca-SemiBold.woff2) format("woff2"),url(https://static.hsappstatic.net/ui-fonts/static-1.286/fonts/LexendDeca-SemiBold.woff) format("woff");font-weight:600}@font-face{font-display:swap;font-family:Lexend Deca;src:url(https://static.hsappstatic.net/ui-fonts/static-1.286/fonts/LexendDeca-Bold.woff2) format("woff2"),url(https://static.hsappstatic.net/ui-fonts/static-1.286/fonts/LexendDeca-Bold.woff) format("woff");font-weight:700}body,html{min-height:100%}body{background-color:#fff}

